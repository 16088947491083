import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { setConfiguration } from "react-grid-system"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/global"
import theme from "../styles/theme"
import SiteFooter from "./sitefooter/siteFooter"
import SiteHeader from "./siteHeader/siteHeader"

setConfiguration({
  breakpoints: [576, 768, 1028, 1366],
  containerWidths: [768, 1028, 1366, 1640],
  gutterWidth: 16,
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        ...siteMeta
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <SiteHeader siteTitle={data.site.siteMetadata.title} />
      {children}
      <SiteFooter />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default Layout
