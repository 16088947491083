import React from 'react'
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import WidgetWrapper from './widgetWrapper'
import styled from 'styled-components'

const Wrapper = styled(WidgetWrapper)`
  @media only screen and (max-width: 1366px) {
    border-right: 0;
  }
  @media only screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
  }
`

const IconList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
`

const IconItem = styled.li`
  flex: 0 1 auto;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1.1em;
  margin-top: 0;
  &:last-child {
    margin-right: 0;
  }
`

const IconLink = styled.a`
  color: ${props => props.theme.colors.body}
`

const IconTitle = styled.span`
  display: none;
`

const SocialWidget = () => {
  return (
    <Wrapper title={`Follow Us`}>
      <IconList>
        <IconItem>
          <IconLink
            href="https://www.linkedin.com/company/parkerwhite-brand-interactive"
            target="_bank"
            rel="nofollow noreferrer"
          >
            <FaLinkedin />
            <IconTitle>ParkerWhite LinkedIn</IconTitle>
          </IconLink>
        </IconItem>
        <IconItem>
          <IconLink
            href="https://www.instagram.com/parkerwhiteagency/"
            target="_bank"
            rel="nofollow noreferrer"
          >
            <FaInstagram />
            <IconTitle>ParkerWhite Instagram</IconTitle>
          </IconLink>
        </IconItem>
        <IconItem>
          <IconLink
            href="https://twitter.com/parkerwhite"
            target="_bank"
            rel="nofollow noreferrer"
          >
            <FaTwitter />
            <IconTitle>ParkerWhite Twitter</IconTitle>
          </IconLink>
        </IconItem>
        <IconItem>
          <IconLink
            href="https://www.facebook.com/ParkerWhiteBrandInteractive/"
            target="_bank"
            rel="nofollow noreferrer"
          >
            <FaFacebookSquare />
            <IconTitle>ParkerWhite Facebook</IconTitle>
          </IconLink>
        </IconItem>
      </IconList>
    </Wrapper>
  )
}

export default SocialWidget
